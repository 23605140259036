body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'SourceSansPro-Regular';
  src: local('SourceSansPro-Regular'), url(./asset/font/Source_Sans_Pro/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-SemiBold';
  src: local('SourceSansPro-SemiBold'), url(./asset/font/Source_Sans_Pro/SourceSansPro-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-Bold';
  src: local('SourceSansPro-Bold'), url(./asset/font/Source_Sans_Pro/SourceSansPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: local('OpenSans-Bold'), url(./asset/font/Open_Sans/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: local('OpenSans-SemiBold'), url(./asset/font/Open_Sans/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'), url(./asset/font/Open_Sans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Light';
  src: local('OpenSans-Light'), url(./asset/font/Open_Sans/OpenSans-Light.ttf) format('truetype');
}